footer {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  display: inline-block;
  padding: 1rem 1rem 0 1rem;
}

footer p {
  line-height: 1.2;
  overflow-wrap: break-word;
  color: var(--lt-grey);
}

footer a {
  color: var(--lt-grey);
  text-decoration: none;
}

footer a:hover {
  color: var(--accent-color);
}