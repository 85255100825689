/* use across all components, index.js/html */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
}

#root {
  margin:auto;
  text-align: center;
  height: 100%;
  min-height: 100vh;
  width:100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* my stuff */
@font-face {
  font-family: 'Ultra';
  src: local('Ultra'), url(./assets/fonts/Ultra.otf) format('opentype');
}
@font-face {
  font-family: 'Azedo';
  src: local('Azedo'), url(./assets/fonts/Azedo-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Azedo Bold';
  src: local('Azedo Bold'), url(./assets/fonts/Azedo-Bold.otf) format('opentype');
}