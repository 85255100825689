.card {
    display: flex;
    flex-wrap: wrap;
    min-width: 200px;
    padding: 5px;
    margin: 20px auto 0;
    width: 100%;
}
.card-img,
.card-head,
.card-body {
    color: var(--white);
}

.card-img {
    width: 25%;
    /* flex-grow: 1; */
}
.card-img img {
    width: 100%;
    object-fit:contain;
}

.card-text {
    /* flex-grow: 2; */
    width: 70%;
    margin-left: 10px;
}

.card-head,
.card-body {
    text-align: left;
}