.hero {
  margin: 20% 0 0;
  text-align: right;
}

.section-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  text-align: center;
}

.section-btn {
  padding: 0.5rem;
  margin: 0 5px;
  background-color: transparent;
  color: aqua;
  text-decoration: none;
  border: 2px solid aqua;
}

/* .title {
  padding: 0 40px 0 0;
}

.subtitle {
  line-height: .5;
} */

.skills {
  width: 220px;
  margin: auto;
  padding: 20px;
  border: double 5px rgba(141, 235, 128, 0.5);
  border-radius: 10px;
  box-shadow: 0 0 20px 5px var(--grey);
}
.skills p {
  text-align: justify;
  /* width: 180px; */
  margin: auto;
}

.intro {
  margin: 3rem auto;
}

/* 80s tron animation */ /*
.bg-animation {
  position: relative;
  height: 100%;
  background: linear-gradient(#6084d7 25%, #a2cef4 50%, #a2cef4 50%, #6084d7 100%);
}

.wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  perspective: 360px;
  perspective-origin: 50% 50%;
}

.top-plane, .bottom-plane {
  width: 200%;
  height: 130%;
  position: absolute;
  bottom: -30%;
  left: -50%;
  background-image: -webkit-linear-gradient(#a2cef4 2px, transparent 2px), -webkit-linear-gradient(left, #a2cef4 2px, transparent 2px);
  background-size: 100px 100px,100px 100px;
  background-position: -1px -1px,-1px -1px;
  transform: rotateX(85deg);
  animation: planeMoveTop 2s infinite linear;
}

.bottom-plane {
  transform: rotateX(-85deg);
  top: -30%;
  animation: planeMoveBot 2s infinite linear;
}

@keyframes planeMoveTop {
  from {
    background-position: 0px -100px,0px 0px;
  }
  to {
    background-position: 0px 0px, 100px 0px;
  }
}
@keyframes planeMoveBot {
  from {
    background-position: 0px 0px,0px 0px;
  }
  to {
    background-position: 0px -100px, 100px 0px;
  }
} */