/* simple css helpers for components */
:root {
  --main-font: 'Roboto Flex', sans-serif;
  --accent-color: #8deb80;
  --black: #010101;
  --dk-grey: #1c1c1c;
  --grey: #4a4a4a;
  --lt-grey: #b0b3b8;
  --white: #e4e6eb;
}

#root {
  margin:auto;
  text-align: center;
  height: 100vh;
  width:100vw;
  background-color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--white);
  margin: 0;
}

p {
  color: var(--lt-grey);
}

.home-btn {
  display: inline-block;
  border-radius: 80px;
  border: 0.2rem double var(--accent-color);
  font-size: 2em;
  background-color: var(--dk-grey);
  color: var(--accent-color);
  padding: 1rem;
  margin: 2rem 0 1rem;
}

.home-btn a {
  color: var(--accent-color);
  text-decoration: none;
}

/*.home-btn:hover {
  /* background-color: #282c34;
  color: aquamarine;
  border: 0.2rem double aquamarine;
}*/

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style-type: none;
}

.avatar {
  width: 30%;
}

.avatar img {
  width: 100%;
}

/* css animation for main with menu */
#main {
  display: inline-block;
  transition: margin-left 0.5s;
  text-align: center;
  width: calc(100% - 3rem);
  height: 100vh;
  margin: 0 0 0 3rem;
}

/* text/font styles */
.title {
  font-family: Azedo, "Andale Mono";
  font-size: 6rem;
  /* text-transform: uppercase; */
  color: var(--accent-color);
  opacity: .5;
  margin: 0 auto;
  text-align: right;
  margin: auto;
  width: 90%;
}

.subtitle {
  text-align: left;
  font-family: "Andale Mono", var(--main-font);
  font-weight: 500;
  font-size: 2rem;
  margin: 0 auto 1rem;
  color: var(--white);
}

.hide {
  display: none;
}

/* .content {
  overflow-y: scroll;
  height: 80vh;
} */

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.details {
  margin: auto;
  width: 90%;
  line-height: 1.3;
  padding: 0 0 1rem;
}

.link {
  color: var(--lt-grey);
  text-decoration: none;
}

.title-link {
  color: var(--white);
  text-decoration: none;
}

.link:hover,
.title-link:hover {
    color: var(--accent-color);
}

.link-list li {
  padding: 0 0 10px;
}

@media screen and (orientation: portrait) {
 .title {font-size: 5rem; font-weight: 500; text-align: center;}
 .subtitle {font-size: 2rem; font-weight: 400;} 
}